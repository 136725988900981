import { Trans } from "@lingui/macro";
import { Box, Grid, Stack, Typography, useMediaQuery } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import React, { useState } from "react";
import { motion } from "framer-motion";
import CardColor from "../../../components/CardColor";
import Container from "../../../components/Container";
import Header from "../../../components/Header";
import Seo from "../../../components/seo";
import SecondaryButton from "../../../components/SecondaryButton";
import HeroLandingV2 from "../../../components/HeroLandingV2";
import ProductModal from "../../../components/ProductModal";
import clientNeedsImage from "../../../images/segments/insurance-pensions/mapfre/service.png";
import blueIcon from "../../../images/icons/blue.png";
import greenIcon from "../../../images/icons/green.png";
import yellowIcon from "../../../images/icons/yellow.png";
import pinkIcon from "../../../images/icons/pink.png";
import screenerMapfre from "../../../images/segments/insurance-pensions/mapfre/Web_Mapfre.png";
import CommentsSlider from "../../../components/CommentsSlider";
import banner from "../../../images/segments/insurance-pensions/mapfre/Contrato.png";
import logo from "../../../images/segments/insurance-pensions/mapfre/mapfre-logo-blue.png";
import { theme } from "../../../themeV2";

const metadata = {
  title: "Success Case - Mapfre",
  description: "Allfunds Digital has created and successfully deployed a global online sales application for Mapfre, incorporating subscription and management of all savings solutions online.",
};

const ITEMS = [
  {
    icon: yellowIcon,
    color: theme.palette.colors.yellow.main,
    title: <Trans>Offer</Trans>,
    description: (
      <Trans>
        Mapfre clients a single integrated online solution covering financial planning, product choice (such as unitlinked and custom fund portfolios), subscription, and instant payment
      </Trans>
    ),
  },
  {
    icon: greenIcon,
    color: theme.palette.colors.green.main,
    title: (
      <Trans>
        Allow
      </Trans>
    ),
    description: (
      <Trans>
        Mapfre clients the freedom to build their own basket of funds, automate contributions, and monitor investments online.
      </Trans>
    ),
  },
  {
    icon: pinkIcon,
    color: theme.palette.colors.purple.main,
    title: <Trans>Provide</Trans>,
    description: (
      <Trans>
        Mapfre’s commercial agents with straightforward tools to track and oversee client operations on-line
      </Trans>
    ),
  },
  {
    icon: blueIcon,
    color: theme.palette.colors.blue.chart,
    title: <Trans>Develop</Trans>,
    description: (
      <Trans>
        A future proof, modern, secure and scalable website on a single platform allowing Mapfre to deploy in any country with minimal changes.
      </Trans>
    ),
  },
];

const sliders = [
  {
    comment: "At MAPFRE we keep working on tools to strengthen our relationship with all our clients. This project will definitely bring us closer to end client. Also, we have improved our distributor's capabilities as well as the quality of our advisory, savings and investment solutions. We are thankful to the huge effort made by the Allfunds Digital team.",
  },
];

const getAnimationProps = (direction) => ({
  initial: "hidden",
  whileInView: "visible",
  viewport: { once: true, amount: 0.8 },
  transition: { duration: 0.8 },
  variants: {
    hidden: { opacity: 0, y: direction === "down" ? 70 : -70 },
    visible: { opacity: 1, y: 0 },
  },
});

function AnimatedDiv({ isMobile, direction, children }) {
  if (isMobile) {
    return <div>{children}</div>;
  }
  return <motion.div {...getAnimationProps(direction)}>{children}</motion.div>;
}

function Mapfre() {
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [showModal, setShowModal] = useState(false);

  const renderRequestButton = () => {
    return (
      <SecondaryButton
        handleOnclickInfo={() => setShowModal(true)}
        text={<Trans>Request info</Trans>}
      />
    );
  };

  const renderCard = ({ color, icon, title, description }) => {
    return (
      <Grid item xs={12} sm={6} lg={3}>
        <CardColor mt={0} color={color} icon={icon} title={title} description={description} />
      </Grid>
    );
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Seo title={metadata.title} description={metadata.description} />
      <Header />
      <HeroLandingV2
        title={
          <Trans>Digitalize Customer Relationship Management</Trans>
        }
        description={
          <Trans>
            Mapfre is the largest Spanish-owned insurer in the world and the largest multinational insurance company in Latin America. With around €30Bn revenues, serving 26 million people around the world 35.000 employees and 5.000 offices.
          </Trans>
        }
        handleOnclickInfo={() => setShowModal(true)}
        backgroundImage={banner}
        kindImage={logo}
      />
      <Container pt={{ xs: 6, sm: 8, lg: 5 }}>
        <Grid container spacing={{ xs: 3, lg: 6 }} alignItems="center">
          <Grid item xs={12} lg={7}>
            <Stack
              spacing={{ xs: 2, md: 3 }}
            >
              <Typography variant="h2">
                Client Needs
              </Typography>
              <Typography>
                As part of its global expansion, Mapfre needed to move all customer relationship management of its savings solutions offered in Peru to a user friendly, online,
                digital environment. The solution needed to be easily scalable and versatile to apply in other markets in the future.
              </Typography>
              <Box display="flex" justifyContent={{ xs: "center", sm: "flex-start" }}>
                {renderRequestButton()}
              </Box>
            </Stack>
          </Grid>
          <Grid item xs={12} lg={5} justifyContent={{ xs: "center", lg: "flex-start" }} display="flex">
            <Box pt={{ xs: 4, lg: 2}} width={{ xs: "100%", sm: "80%", lg: "100%" }}>
              <img width="100%" alt="third" src={clientNeedsImage} />
            </Box>
          </Grid>
        </Grid>
      </Container>
      <Container pt={{ xs: 8, sm: 8, md: 10 }}>
        <Stack flexDirection="column" alignItems="center" spacing={{ xs: 2, md: 3 }}>
          <Typography variant="h2">
            Allfunds Solution
          </Typography>
          <Box>
            <AnimatedDiv isMobile={isMobile} direction="down">
              <Grid container spacing={2}>
                {ITEMS.map((item, key) => (
                        renderCard({ ...item, key })
                      ))}
              </Grid>
            </AnimatedDiv>
          </Box>
          <Box>
            {renderRequestButton()}
          </Box>
        </Stack>
      </Container>
      <Container pt={{ xs: 2, sm: 8, lg: 10 }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} lg={7} justifyContent={{ xs: "center", md: "flex-start" }}>
            <img width="100%" alt="third" src={screenerMapfre} />
          </Grid>
          <Grid item xs={12} lg={5} mt={0}>
            <Stack
              spacing={{ xs: 2, md: 3 }}
            >
              <Typography variant="h2">
                Project Outcome
              </Typography>
              <Typography>
                Allfunds has created and successfully deployed a global online sales application for Mapfre, incorporating subscription and management of all savings solutions online.
                Equipped with daily management and optimisation tools, both Mapfre commercial agents and clients can oversee and reshape the accounts.
              </Typography>
              <Box display="flex" justifyContent={{ xs: "center", sm: "flex-start" }}>
                {renderRequestButton()}
              </Box>
            </Stack>
          </Grid>
        </Grid>
      </Container>
      <Container pt={{ xs: 2, sm: 6, lg: 8 }} pb={7}>
        <CommentsSlider sliders={sliders} />
      </Container>
      {showModal && (
        <ProductModal
          onClose={() => setShowModal(false)}
          subject="Success Case - Mapfre"
          product="success-case-mapfre"
          hasTemplate={false}
        />
      )}
    </ThemeProvider>
  );
}

export default Mapfre;
